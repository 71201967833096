import React from 'react'
// import PropTypes from 'prop-types'

import {
  StyledEngineProvider,
  // makeStyles,
  alpha, Card, Avatar, Tooltip, Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  teamMemberCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'unset',
    backgroundColor: 'transparent',
  },

  teamMemberTooltip: {
    textAlign: 'center',
  },
  teamMemberAvatar: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
    border: `${alpha(theme.palette.secondary.main, 0.57)} ${theme.spacing(1.5)} solid`,
    fontSize: theme.typography.h3.fontSize,

    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(28),
      height: theme.spacing(28),
      borderWidth: theme.spacing(2),
    },
    [theme.breakpoints.only('md')]: {
      width: theme.spacing(22),
      height: theme.spacing(22),
      borderWidth: theme.spacing(1.5),
    },
    [theme.breakpoints.only('sm')]: {
      width: theme.spacing(20),
      height: theme.spacing(20),
      borderWidth: theme.spacing(1.5),
    },
    [theme.breakpoints.only('xs')]: {
      width: theme.spacing(18),
      height: theme.spacing(18),
      borderWidth: theme.spacing(1.5),
    },
  },
  teamMemberAvatarImg: {
    height: 'auto',
  },
}))

const TeamMember = ({ dataSource, imageFolder }) => {
  const classes = useStyles()
  // console.log(dataSource)
  return (
    <StyledEngineProvider injectFirst>
      <Card elevation={0} className={classes.teamMemberCard}>
        <Avatar
          alt={dataSource.fullName}
          src={
            dataSource.photo &&
            `https://msciteh.educatiepentrustiinta.ro/images/${imageFolder}/${
              dataSource.photo
            }`
          }
          classes={{
            root: classes.teamMemberAvatar,
            img: classes.teamMemberAvatarImg,
          }}
        >
          {dataSource.initials}
        </Avatar>
        <Tooltip
          title={
            dataSource.organisationFull
              ? dataSource.organisationFull
              : dataSource.organisation
          }
          placement="bottom"
          className={classes.teamMemberTooltip}
        >
          <div>
            <Typography variant="h6" component="div">
              {dataSource.fullName}
            </Typography>
            <Typography variant="button" component="div">
              {dataSource.position && dataSource.position}
              {dataSource.position && dataSource.organisation && ', '}
              {dataSource.organisation && dataSource.organisation}
            </Typography>
          </div>
        </Tooltip>
      </Card>
    </StyledEngineProvider>
  )
}

TeamMember.propTypes = {}

export default TeamMember
